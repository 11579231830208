import { useParams } from "react-router-dom";
import { fetchOrganization } from "../api/api";
import { useAppContext } from "../context/Context";

function FindOrganizationFromUrl() {
  const { company, setCompany } = useAppContext();
  const { companyCodeFromUrl } = useParams();

  const findCompanyInUrl = () => {
    if (companyCodeFromUrl) {
      // Check if companyCodeFromUrl is defined
      if (company && company.company_code === companyCodeFromUrl) {
        // If this is fulfilled, then the URL has the same company code as the
        // entered company code from the form input field that the user has entered
        // in the previous page report (localhost/en/report/).
      } else {
        // If the user enters the URL directly, we need to fetch the data based on the URL.
        // The companyCode comes from the URL and the company.company_code comes
        // after you get it from the backend from fetchOrganization API
        const fetchCompanyFromUrl = async () => {
          const result = await fetchOrganization(companyCodeFromUrl);
          setCompany(result);
        };
        fetchCompanyFromUrl();
      }
    }
  };

  findCompanyInUrl();
  return;
}

export default FindOrganizationFromUrl;
