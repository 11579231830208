import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import LanguageSelector from "./LanguageSelector";
import logo from "../svg/logo.svg";

function ResponsiveAppBar() {
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#fbf9f8", p: 2 }}
      elevation={0}
    >
      <Container maxWidth="md">
        <Toolbar
          disableGutters
          sx={{
            justifyContent: "space-between", // Align items horizontally
            alignItems: "center", // Align items vertically
          }}
        >
          <img src={logo} alt="Logo" style={{ maxWidth: "100px" }} />
          <LanguageSelector />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
