import FileList from "../../report/components/FileList";
import { UploadedFileDataInterface } from "../../../api/api";
import { Grid, Typography } from "@mui/material";
import { t } from "i18next";

interface UploadedFilesProps {
  files: UploadedFileDataInterface[];
}

const UploadedFiles: React.FC<UploadedFilesProps> = ({ files }) => {
  return (
    <Grid
      item
      sx={{
        backgroundColor: "#fbf9f8",
        mb: 4,
      }}
    >
      <Typography variant="h3">{t("uploadFilesTitle")}</Typography>
      <FileList files={files} />
    </Grid>
  );
};

export default UploadedFiles;
