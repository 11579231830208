import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAppContext } from "../context/Context";

function LanguageSelector() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setLanguage } = useAppContext();

  const handleChange = (event: SelectChangeEvent) => {
    const selectedLanguage = event.target.value as string;

    // Get the current path without the language prefix
    const currentPath = window.location.pathname.replace(/^\/[a-z]{2}/, "");

    // Build the new path of the selected language, but the selected language is in the form of en-US and we only need the "en" part
    const newPath = `/${selectedLanguage.split("-")[0]}${currentPath}`;

    // Build the new path with the selected language and the current path
    // const newPath = `/${selectedLanguage}${currentPath}`;

    i18n.changeLanguage(selectedLanguage);
    setLanguage(selectedLanguage); // Need to set the language change in the context to re-render the page properly
    navigate(newPath);
  };

  // Get a list of available languages from i18next
  const availableLanguages: string[] = (
    i18n.options.supportedLngs || []
  ).filter((lng: string) => lng !== "cimode");

  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel>{t("language")}</InputLabel>
          <Select
            value={i18n.language}
            label={t("language")}
            onChange={handleChange}
          >
            {availableLanguages.map((languageCode: string, index: number) => {
              // Fetch all the names of the language codes: en -> English etc.
              const allLanguageNames = i18n.options.resources;
              // Use languageCode to find the corresponding name of the language
              const languageName =
                languageCode &&
                allLanguageNames &&
                allLanguageNames[languageCode].languageName;
              return (
                <MenuItem key={index} value={languageCode}>
                  {languageName?.toString()}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}

export default LanguageSelector;
