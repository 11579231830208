import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { fetchReport } from "../../../api/api";
import { useAppContext } from "../../../context/Context";
import LoadingSpinner from "../../../components/LoadingSpinner";

interface SearchReportProps {}

const SearchReport: React.FC<SearchReportProps> = ({}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");

  const { setReport } = useAppContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);
    setIsError(false);

    try {
      const result = await fetchReport(inputValue);
      // Set the company in the context
      setReport(result);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const isErrorMsg = () => {
    if (isError) {
      return <Typography> {t("errorCantFindReport")}</Typography>;
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h1" paragraph>
          {t("followUpReportHeader")}
        </Typography>
        <Typography variant="body1" paragraph>
          {t("followUpReportInformation")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid item sx={{ mb: 2 }}>
            <TextField
              fullWidth
              autoComplete="off"
              label={t("password")}
              variant="outlined"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Grid>
          <Grid item sx={{ mb: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              {t("goToReportBtn")}
            </Button>
            {isLoading && <LoadingSpinner />}
          </Grid>
        </form>
      </Grid>

      <Typography variant="body1">{isErrorMsg()}</Typography>
    </>
  );
};

export default SearchReport;
