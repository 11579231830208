import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchOrganization } from "../../api/api";
import { useAppContext } from "../../context/Context";
import ReportSubmissionSuccessful from "./components/ReportSubmissionSuccessful";
import SelectorSubsidiary from "./components/SelectorSubsidiary";
import Form from "./components/form/Form";

interface HomeProps {}

const Report: React.FC<HomeProps> = ({}) => {
  const { t } = useTranslation();
  const {
    company,
    setCompany,
    setSelectedCompanies,
    isError,
    submitSuccess,
    report,
  } = useAppContext();
  const { companyCodeFromUrl } = useParams();

  const [displaySubsidiarySelector, setDisplaySubsidiarySelector] =
    useState<boolean>(false);

  useEffect(() => {}, [submitSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page (x, y)
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  useEffect(() => {
    if (companyCodeFromUrl) {
      // Check if companyCodeFromUrl is defined
      if (company && company.company_code === companyCodeFromUrl) {
        // If this is fulfilled, then the URL has the same company code as the
        // entered company code from the form input field that the user has entered
        // in the previous page report (localhost/en/report/).
      } else {
        // If the user enters the URL directly, we need to fetch the data based on the URL.
        // The companyCode comes from the URL and the company.company_code comes
        // after you get it from the backend from fetchOrganization API
        const fetchCompanyFromUrl = async () => {
          const result = await fetchOrganization(companyCodeFromUrl);
          setCompany(result);
        };
        fetchCompanyFromUrl();
      }
    }

    // If company has no subsidiaries, we should set selectedCompanies to company
    // because if a company has subsidiaries, the user needs to select the subsidiaries
    // manually in a list, but here we set it automatically if there are no subsidiaries
    if (company && company.subsidiaries?.length === 0) {
      setSelectedCompanies([company]);
      setDisplaySubsidiarySelector(false);
    }

    // If company has subsidiaries, we should display the selector
    if (company && company.subsidiaries && company.subsidiaries?.length > 0) {
      setDisplaySubsidiarySelector(true);
    }
  }, [companyCodeFromUrl, company]); // Include companyCodeFromUrl in the dependency

  const isErrorMsg = () => {
    if (isError) {
      return (
        <Typography variant="body1" paragraph>
          {t("createReportError")}
        </Typography>
      );
    }
  };

  const renderComponents = () => {
    if (submitSuccess === false) {
      return (
        <>
          <Typography
            variant="h1"
            paragraph
            sx={{
              p: 2,
              backgroundColor: "#eaeae8",
              borderRadius: "5px",
              fontWeight: 500,
              display: "flex",
              justifyContent: "space-between", // Align items horizontally
              alignItems: "center", // Align items vertically
            }}
          >
            {company && company.name}
          </Typography>

          {displaySubsidiarySelector && <SelectorSubsidiary />}

          <Typography variant="h2" paragraph>
            {t("reportErrand")}
          </Typography>
          <Form />

          {isErrorMsg()}
        </>
      );
    } else {
      return (
        <>
          {report && <ReportSubmissionSuccessful report={report && report} />}
        </>
      );
    }
  };

  return <>{renderComponents()}</>;
};

export default Report;
