import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4FA190", // Replace with your desired primary color
    },
    background: {
      default: "#F7F4F1",
    },
    // Other palette customization options go here (secondary, error, etc.)
  },
  typography: {
    h1: {
      fontSize: "2.2rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.8rem",
      fontWeight: 400,
    },
    h3: {
      fontSize: "1.3rem",
      fontWeight: 400,
    },
    body1: {
      fontSize: "0.9rem",
    },
  },
});

export default theme;
