import { Grid, Typography, Link as MuiLink } from "@mui/material";
import React from "react";
import { IReportFetchResponse } from "../../../api/api";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "./CopyToClipboardButton";
import DownloadCodeButton from "./DownloadCodeButton";

interface HomeProps {
  report: IReportFetchResponse;
}

const ReportSubmissionSuccessful: React.FC<HomeProps> = ({ report }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" paragraph>
            {t("reportReceivedConfirmation")}
          </Typography>
          <Typography variant="body1">{t("workFastAsWeCan")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" paragraph>
            <b>{t("important")}:</b> {t("storePasswordText")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontWeight: 600 }} paragraph>
            {t("uniquePassword")}:
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            p: 2,
            backgroundColor: "#eaeae8",
            borderRadius: "5px",
            fontWeight: 500,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body1">{report && report.uid}</Typography>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 1, sm: 0 } }}>
                <CopyToClipboardButton textToCopy={report && report.uid} />
              </Grid>
              <Grid item>
                <DownloadCodeButton password={report && report.uid} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" paragraph>
            {t("followReport")}{" "}
            <MuiLink
              underline="none"
              component={RouterLink}
              // Remove the region code which is after the dash
              // such as "en-US" -> "en" to use the correct language code in the URL
              to={`/${currentLanguage.split("-")[0]}/followup`}
            >
              report.tissla.se/followup
            </MuiLink>
            .
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontWeight: 600 }} paragraph>
            {t("closeWindow")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportSubmissionSuccessful;
