import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onButtonClick = () => {
    navigate("organization");
  };

  return (
    <>
      <Typography variant="h1" paragraph>
        Tissla {t("whistleblowingPlatform")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("homePageParagraph")}
      </Typography>

      <Grid item sx={{ mt: 4 }}>
        <Button
          onClick={() => {
            onButtonClick();
          }}
          variant="contained"
          sx={{ mr: 2 }}
        >
          {t("buttonReport")}
        </Button>

        <Button
          onClick={() => {
            navigate("followup");
          }}
          variant="outlined"
        >
          {t("buttonFollowUp")}
        </Button>
      </Grid>
    </>
  );
};

export default Home;
