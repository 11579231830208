import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchOrganization, Organization } from "../api/api";
import { useAppContext } from "../context/Context";
import LoadingSpinner from "../components/LoadingSpinner";

interface HomeProps {}

const OrganizationSearch: React.FC<HomeProps> = ({}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const { company, setCompany } = useAppContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);
    setIsError(false);

    try {
      const result = await fetchOrganization(inputValue);

      // Sets the company in the context
      setCompany(result);

      navigate(result.company_code ?? "");
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const isErrorMsg = () => {
    if (isError) {
      return (
        <Typography variant="body1" paragraph>
          {" "}
          {t("errorCantFindCompany")}
        </Typography>
      );
    }
  };

  return (
    <>
      <Typography variant="h1" paragraph>
        {t("searchOrganization")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("searchOrganizationParagraph")}
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <TextField
            fullWidth
            autoComplete="off"
            label={t("organizationCodeLabel")}
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </Grid>
        <Grid item sx={{ mb: 2 }}>
          <Button variant="contained" color="primary" type="submit">
            {t("orgCodeSearchBtn")}
          </Button>
        </Grid>
        {isLoading && <LoadingSpinner />}
      </form>

      {isErrorMsg()}
    </>
  );
};

export default OrganizationSearch;
