import i18n from "i18next";
import { changeLanguage } from "i18next";

const SetInitialLanguage = () => {
  const path = window.location.pathname;
  const match = path.match(/^\/([a-z]{2})/); // Match the language code at the beginning of the URL => result is ["/en", "en""]
  const parsedLanguage = match ? match[1] : ""; // Default to '' if no match
  const supportedLanguages = i18n.options.supportedLngs || []; // Fetch supported languages from your JSON
  const defaultLng = i18n.options.lng;

  // Function that maps parsedLanguage (eg "en") to the supported language code (eg "en-US")
  const mapParsedLanguageToSupportedLanguage = (parsedLanguage: string) => {
    const supportedLanguage = supportedLanguages.find(
      (supportedLanguage: string) =>
        supportedLanguage.startsWith(parsedLanguage)
    );
    return supportedLanguage;
  };

  try {
    const fourDigitLanguageCode = mapParsedLanguageToSupportedLanguage(
      parsedLanguage
    ) as string;
    changeLanguage(fourDigitLanguageCode);

    // This reloads the page with the correct language code in the URL
    // and there is no risk that the Contentful will receive the wrong language code which
    // could happen if we just change the language in i18n
    return fourDigitLanguageCode;
  } catch (error) {
    // If no language code is found in the URL, use the default language from i18n
    changeLanguage(defaultLng);
    console.log("error", error);
    return defaultLng as string;
  }
};

export default SetInitialLanguage;
