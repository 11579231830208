import { Typography } from "@mui/material";
import { t } from "i18next";
import { useAppContext } from "../../../context/Context";

const IntroductionText: React.FC = ({}) => {
  const { company } = useAppContext();
  return (
    <>
      <Typography variant="h1" paragraph>
        {t("welcomeToWBChannel")} {company && company.name}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("welcomeToWBChannelParagraph1")} {t("welcomeToWBChannelParagraph2")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("welcomeToWBChannelParagraph3")}
      </Typography>
    </>
  );
};

export default IntroductionText;
