import { Button, Chip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { IReportFetchResponse, uploadMultipleFiles } from "../../../api/api";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Chat from "./Chat";
import UploadedFiles from "./UploadedFiles";
import { fetchUploadedFiles } from "../../../api/api";
import { useAppContext } from "../../../context/Context";

import UploadFiles from "../../../components/UploadFiles";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ReportSummary from "./ReportSummary";
import Introduction from "./Introduction";

interface IProps {
  report: IReportFetchResponse;
}

const FollowupReport: React.FC<IProps> = ({ report }) => {
  const { t } = useTranslation(); // Use the useTranslation hook

  // Uploaded files
  const { uploadedFiles, setUploadedFiles } = useAppContext();
  const { selectedFiles, setSelectedFiles } = useAppContext();
  const { isLoading, setIsLoading } = useAppContext();

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const files = await fetchUploadedFiles(report.uid);
        setUploadedFiles(files);
      } catch (error) {
        console.error("Error fetching uploaded files:", error);
      }
    };

    fetchFiles();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await uploadMultipleFiles(report.uid, selectedFiles);
      const files = await fetchUploadedFiles(report.uid);
      setUploadedFiles(files);
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const names = () => {
    if (report && report.organizations) {
      return report.organizations?.map((company) => {
        return <Chip sx={{ ml: 1 }} label={company.name} />;
      });
    }
  };

  const renderCompany = () => {
    return (
      <>
        <Typography variant="h1" paragraph>
          {t("titleReportFollowup")}
        </Typography>
        <Typography variant="body1" paragraph>
          <Typography variant="body1" paragraph>
            {t("descrReportFollowup")}
          </Typography>
          {t("reportAssociatedWith")}:{names()}
        </Typography>
      </>
    );
  };

  const renderComponents = () => {
    return (
      <>
        <Introduction report={report} />
        <Chat />
        <Paper
          elevation={0}
          sx={{
            backgroundColor: "#fbf9f8",
            mb: 4,
            border: "1px solid #cfcfcf",
            p: 5,
          }}
        >
          <UploadedFiles files={uploadedFiles} />
          <Typography variant="body1" paragraph>
            {t("uploadFiles")}
          </Typography>

          <UploadFiles />

          <Button
            variant="contained"
            sx={{ mt: 3 }}
            onClick={() => handleSubmit()}
          >
            {t("uploadButton")}
          </Button>
          {isLoading && <LoadingSpinner />}
        </Paper>
        <ReportSummary report={report && report} />
      </>
    );
  };

  return <>{renderComponents()}</>;
};

export default FollowupReport;
