import { createClient } from "contentful";
import i18n from "i18next";

const client = createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: "hwck40krmvke",
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: "WAWWpBeFSy7mAexAy2XxnIrABsAZ08F1sQZBzTAg5vY",
});

export const fetchPolicy = async () => {
  const entries = await client.getEntry("3o7zuXt7MQh6Jo9c7B2TfM", {
    locale: i18n.language,
  });
  return entries;
};

// Entry ID: 3o7zuXt7MQh6Jo9c7B2TfM
// content type id: policyPage
// Space ID: hwck40krmvke
// accessToken API key: WAWWpBeFSy7mAexAy2XxnIrABsAZ08F1sQZBzTAg5vY
