import { Button } from "@mui/material";
import { t } from "i18next";
import DescriptionIcon from "@mui/icons-material/Description";
import { useNavigate } from "react-router-dom";

const PoliciesButton: React.FC = ({}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("policy");
  };
  return (
    <Button
      variant="outlined"
      disableElevation
      onClick={() => handleClick()}
      startIcon={<DescriptionIcon />}
    >
      {t("buttonPolicies")}
    </Button>
  );
};

export default PoliciesButton;
