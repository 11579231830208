import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import UploadFiles from "../../../../components/UploadFiles";
import { t } from "i18next";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import RenderTextFields from "./RenderTextFields";
import { SubmitReport } from "./SubmitReport";
import { useAppContext } from "../../../../context/Context";

interface FormProps {}

const Form: React.FC<FormProps> = () => {
  const {
    textFields,
    selectedCompanies,
    selectedFiles,
    setIsError,
    setReport,
    setIsLoading,
    isLoading,
    setSubmitSuccess,
  } = useAppContext();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Add isLoading and isError here
    setIsLoading(true);
    setIsError(false);
    try {
      const result = await SubmitReport(
        textFields,
        selectedCompanies,
        selectedFiles
      );
      setReport(result as any);
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
    setSubmitSuccess(true);
  };

  const form = () => {
    return (
      <form onSubmit={handleSubmit}>
        <RenderTextFields />

        <Grid item>
          <Typography variant="h3" paragraph>
            {t("uploadFilesTitle")}
          </Typography>
          <UploadFiles />
        </Grid>
        <FormGroup>
          <FormControlLabel
            required
            control={<Checkbox />}
            label={t("confirmCheckboxInformationCorrect")}
          />
        </FormGroup>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          sx={{ mt: 2, mb: 2, width: { xs: "100%", sm: "auto" } }}
        >
          {t("submitReportButton")}
        </Button>
        {isLoading && <LoadingSpinner />}
      </form>
    );
  };

  return <>{form()}</>;
};

export default Form;
