import React, { useEffect } from "react";
import { useAppContext } from "../../context/Context";
import SearchReport from "./components/SearchReport";
import FollowupReport from "./components/FollowupReport";

interface HomeProps {}

const Followup: React.FC<HomeProps> = ({}) => {
  const { report, setReport, setSelectedFiles } = useAppContext();

  useEffect(() => {
    // When the component mounts, reset the report so that the SearchReport component is rendered
    setReport(null as any);
    setSelectedFiles([] as any); // To remove the uploadable files in the context if the user visits the followup page directly
  }, []);

  useEffect(() => {
    // Once the report has been fetched, reload the component
  }, [report]);

  const renderComponents = () => {
    // If there is no report already fetched, render the SearchReport component
    if (!report) {
      return (
        <>
          <SearchReport />
        </>
      );
    } else {
      // If there is a report, render the FollowupReport component
      return <>{report && <FollowupReport report={report && report} />}</>;
    }
  };

  return <>{renderComponents()}</>;
};

export default Followup;
