import { Chip, Typography } from "@mui/material";
import { IReportFetchResponse } from "../../../api/api";
import { t } from "i18next";

interface IProps {
  report: IReportFetchResponse;
}

const Introduction: React.FC<IProps> = ({ report }) => {
  const renderCompanyNames = () => {
    if (report && report.organizations) {
      return report.organizations?.map((company) => {
        return <Chip sx={{ ml: 1 }} label={company.name} />;
      });
    }
  };

  const renderCompany = () => {
    return (
      <>
        <Typography variant="h1" paragraph>
          {t("titleReportFollowup")}
        </Typography>
        <Typography variant="body1" paragraph>
          <Typography variant="body1" paragraph>
            {t("descrReportFollowup")}
          </Typography>
          {t("reportAssociatedWith")}:{renderCompanyNames()}
        </Typography>
      </>
    );
  };
  return <>{renderCompany()}</>;
};
export default Introduction;
