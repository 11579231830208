import {
  Organization,
  InterfaceTextFields,
  createReportWithFiles,
} from "../../../../api/api";

export const SubmitReport = async (
  textFields: InterfaceTextFields,
  selectedCompanies: Organization[],
  selectedFiles: File[]
) => {
  const formDataTextFields = () => {
    const formData = new FormData();

    const appendIfDefined = (fieldName: string, value: string | undefined) => {
      if (value !== undefined) {
        formData.append(fieldName, value);
      }
    };
    appendIfDefined("misconduct_subject", textFields.misconduct_subject);
    appendIfDefined("misconduct_conductor", textFields.misconduct_conductor);
    appendIfDefined("misconduct_date", textFields.misconduct_date);
    appendIfDefined("misconduct_location", textFields.misconduct_location);
    appendIfDefined(
      "misconduct_description",
      textFields.misconduct_description
    );
    appendIfDefined("reported_before", textFields.reported_before);
    appendIfDefined("previous_actions", textFields.previous_actions);
    appendIfDefined("contact_details", textFields.contact_details);

    return formData;
  };

  const formData = formDataTextFields();

  // Append each company individually
  selectedCompanies.forEach((orgId, index) => {
    formData.append(`organization_ids`, orgId.uid);
  });

  // Append each file individually
  selectedFiles.forEach((file, index) => {
    formData.append("files", file);
  });

  try {
    console.log("form data", formData);
    const result = await createReportWithFiles(formData);
    console.log("Sucessfully created report with files", result);
    return result;
  } catch (error) {
    console.error("Failed to submit report:", error);
  }
};
