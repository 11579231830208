import { Button, Chip, Grid, Typography } from "@mui/material";
import { useAppContext } from "../../../../context/Context";
import { useNavigate } from "react-router-dom";
import i18n, { t } from "i18next";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const PolicyIntroduction = () => {
  const { company } = useAppContext();
  const navigate = useNavigate();

  // Get the first two letters of the language code
  const language = i18n.language.substring(0, 2);

  // Render all the subsidiaries from company
  const renderSubsidiariesChip = () => {
    return company?.subsidiaries?.map((subsidiary: any, index: number) => {
      return <Chip key={index} label={subsidiary.name} sx={{ mr: 1, mb: 1 }} />;
    });
  };

  const renderParentCompanyChip = () => {
    return <Chip label={company?.name} sx={{ mr: 1, mb: 1 }} />;
  };

  const renderSubsidiaries = () => {
    // Only render the subsidiaries if there are any
    if (company && company.subsidiaries && company?.subsidiaries?.length > 0) {
      return (
        <Grid item sx={{ mb: 2 }}>
          <Typography variant="body1" paragraph>
            {t("policyRelatedOrganizations")}:
          </Typography>
          {renderParentCompanyChip()}
          {renderSubsidiariesChip()}
        </Grid>
      );
    } else {
      return null;
    }
  };

  // Navigate to the homepage of the company which falls under "/:lang/organization/:companyCode"
  const handleClick = () => {
    navigate(`/${language}/organization/${company?.company_code}`);
  };

  return (
    <>
      <Typography variant="h1" paragraph>
        {t("whistleblowingPolicyOf")} {company && company.name}
      </Typography>

      {renderSubsidiaries()}

      <Typography variant="body1" paragraph>
        {t("whistleblowingPolicyFoundHere")}:
      </Typography>
      <Button
        variant="outlined"
        endIcon={<KeyboardArrowRightIcon />}
        onClick={() => handleClick()}
      >
        {t("buttonWhistleblowingChannel")}
      </Button>
    </>
  );
};

export default PolicyIntroduction;
