import React, { useRef } from "react";
import { useAppContext } from "../../../context/Context";
import {
  MessageList,
  SendMessageRequest,
  fetchMessages,
  sendMessage,
} from "../../../api/api";
import { useEffect, useState } from "react";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SendRoundedIcon from "@mui/icons-material/SendRounded";

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<MessageList>();
  const [messageContent, setMessageContent] = useState<string>();
  const { report } = useAppContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>();

  useEffect(() => {
    const loadMessages = async () => {
      try {
        if (report) {
          const result = report && (await fetchMessages(report.uid));
          setMessages(result);
        }
      } catch (error) {
        console.error("Error fetching messages: ", error);
      }
    };

    loadMessages();
  }, [report]);

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const scrollChatToBottom = () => {
    // Scroll the messages container to the bottom
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollChatToBottom();
  }, []); // Scroll chat to bottom on initial load

  useEffect(() => {
    scrollChatToBottom();
  }, [messages]); // Reloading the chat when a new message is sent and retrieved

  const messageItem = (message: any) => {
    const date = new Date(message.created_at);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const localDateString: string = date.toLocaleString(undefined, options);

    return (
      <Grid container>
        <AccountCircleIcon
          sx={{
            color: "#b6c1be",
            width: 32,
            height: 32,
            mr: 1,
          }}
        />
        <Grid item xs={10} sx={{ mb: 2 }}>
          <Grid
            item
            xs={12}
            sx={{ backgroundColor: "#f5f3f1", p: 1, borderRadius: 2 }}
          >
            {message.content}
          </Grid>
          <Grid item sx={{ p: 1 }}>
            <Typography sx={{ fontSize: 10 }}>{localDateString}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderMessages = () => {
    return messages?.map((message) => {
      return <>{messageItem(message as any)}</>;
    });
  };

  const handleSubmit = async () => {
    if (report && messageContent) {
      const messageData = {
        report_id: report.uid,
        content: messageContent,
      };

      setIsLoading(true);
      setIsError(false);

      try {
        const response = await sendMessage(messageData as SendMessageRequest);
        const updatedMessages = await fetchMessages(messageData.report_id);
        setMessages(updatedMessages);
        setMessageContent("");
      } catch (error) {
        console.error("Failed to send message:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const messageField = () => {
    return (
      <Grid container spacing={1} textAlign="right">
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoComplete="off"
            multiline
            minRows={2}
            maxRows={6}
            label={t("typeMessage")}
            value={messageContent}
            onChange={(e) => setMessageContent(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => handleSubmit()}
            endIcon={<SendRoundedIcon />}
            disabled={isLoading}
          >
            {t("sendMessage")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{ backgroundColor: "#fbf9f8", mb: 4, border: "1px solid #cfcfcf" }}
      >
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Grid container sx={{ borderBottom: "1px solid #e0e0e0", p: 3 }}>
            <Typography variant="h3">{t("messages")}</Typography>
          </Grid>
          <Grid
            container
            xs={12}
            sx={{
              mb: 3,
            }}
          >
            <Grid
              item
              xs={12}
              ref={messagesContainerRef}
              sx={{
                maxHeight: "400px",
                overflowY: "auto",
                p: 3,
                pb: 0,
                mb: 3,
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              {renderMessages()}
            </Grid>
            <Grid item xs={12} sx={{ p: 3, pb: 0, pt: 0 }}>
              {messageField()}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Chat;
