import { Button, Snackbar } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CopyAllIcon from "@mui/icons-material/CopyAll";

interface HomeProps {
  textToCopy: string;
}

// const CompanyHome: React.FC<HomeProps> = ({}) => {
const CopyToClipboardButton: React.FC<HomeProps> = ({ textToCopy }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        startIcon={<CopyAllIcon />}
        size="small"
      >
        {t("copyToClipboardButton")}
      </Button>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={t("copiedToClipboardSnackbar")}
      />
    </>
  );
};

export default CopyToClipboardButton;
