import React, { createContext, useContext, ReactNode, useState } from "react";
import { IReportFetchResponse } from "../api/api";
import {
  UploadedFileDataInterface,
  Organization,
  InterfaceTextFields,
} from "../api/api";
import SetInitialLanguage from "./components/SetInitialLanguage";

// Define the context
type AppContextType = {
  company: Organization | null;
  setCompany: (codes: Organization) => void;
  selectedCompanies: Organization[] | [];
  setSelectedCompanies: (codes: Organization[]) => void;
  report: IReportFetchResponse | null;
  setReport: (codes: IReportFetchResponse) => void;
  selectedFiles: File[];
  setSelectedFiles: (files: File[]) => void;
  uploadedFiles: UploadedFileDataInterface[];
  setUploadedFiles: (files: UploadedFileDataInterface[]) => void;
  textFields: InterfaceTextFields;
  setTextFields: (
    callback: (prevState: InterfaceTextFields) => InterfaceTextFields
  ) => void;
  isError: boolean;
  setIsError: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  submitSuccess: boolean;
  setSubmitSuccess: (value: boolean) => void;
  policy: any;
  setPolicy: (value: any) => void;
  language: string;
  setLanguage: (value: string) => void;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

// Define a custom hook to access the context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return context;
};

// Define a provider component to wrap your app with
type AppContextProviderProps = {
  children: ReactNode;
};

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  const [company, setCompany] = useState<Organization | null>(null);
  const [selectedCompanies, setSelectedCompanies] = useState<
    Organization[] | []
  >([]);
  const [report, setReport] = useState<IReportFetchResponse | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<
    UploadedFileDataInterface[]
  >([]);
  const [textFields, setTextFields] = useState<InterfaceTextFields>({
    misconduct_subject: "",
    misconduct_conductor: "",
    misconduct_date: "",
    misconduct_location: "",
    misconduct_description: "",
    reported_before: "",
    previous_actions: "",
    contact_details: "",
  });
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
  const [policy, setPolicy] = useState<any>(null);
  const [language, setLanguage] = useState<string>(SetInitialLanguage());

  return (
    <AppContext.Provider
      value={{
        company,
        setCompany,
        selectedCompanies,
        setSelectedCompanies,
        report,
        setReport,
        selectedFiles,
        setSelectedFiles,
        uploadedFiles,
        setUploadedFiles,
        textFields,
        setTextFields,
        isError,
        setIsError,
        isLoading,
        setIsLoading,
        submitSuccess,
        setSubmitSuccess,
        policy,
        setPolicy,
        language,
        setLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
