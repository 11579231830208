import { IReportFetchResponse, IReportCreate } from "../../../api/api";
import { t } from "i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

interface IProps {
  report: IReportFetchResponse;
}

const ReportSummary: React.FC<IProps> = ({ report }) => {
  const createData = (created_at: string, data: string) => {
    return { created_at, data };
  };

  const createdAtDate = new Date(report && report.created_at);
  const createdAtDateLocal = createdAtDate.toLocaleDateString();

  const rows = [
    createData(t("reportCreatedAt"), createdAtDateLocal),
    createData(t("formSubjectLabel"), report && report.misconduct_subject),
    createData(t("formDescrLabel"), report && report.misconduct_description),
    createData(t("formWhoLabel"), report && report.misconduct_conductor),
    createData(t("formWhenLabel"), report && report.misconduct_date),
    createData(t("formWhereLabel"), report && report.misconduct_location),
    createData(t("formReportedBeforeLabel"), report && report.reported_before),
    createData(
      t("formPreviousActionsLabel"),
      report && report.previous_actions
    ),
  ];

  // The response from the report.miconduct_description returns
  // a string, where line breaks are denoted by \n that we need
  // to deconstruct below
  const renderTextWithLineBreaks = (value: string) => {
    const listToLineBreak = value.split("\n");
    return (
      <>
        {listToLineBreak.map((data, index) => {
          // Removes empty line breaks (we don't display multiple line breaks)
          if (data) {
            return (
              <Typography key={index} variant="body1" sx={{ pt: 1, pb: 1 }}>
                {data}
              </Typography>
            );
          }
        })}
      </>
    );
  };

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        backgroundColor: "#fbf9f8",
        mb: 4,
        border: "1px solid #cfcfcf",
      }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("caseSummary")}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.created_at}
              </TableCell>
              <TableCell align="left">
                {renderTextWithLineBreaks(row.data)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportSummary;
