import { Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer
      style={{
        textAlign: "center",
        padding: "40px",
        backgroundColor: "#fbf9f8",
      }}
    >
      <Typography variant="body1">{t("footerText")} </Typography>
      <Typography variant="body1">
        <Link
          href="https://www.tissla.se"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.tissla.se
        </Link>
      </Typography>
    </footer>
  );
};

export default Footer;
