import { useEffect } from "react";
import { fetchPolicy } from "../../../../components/cms/Contentful";
import { useAppContext } from "../../../../context/Context";
import RenderContentfulRichText from "../../../../components/cms/RenderContentfulRichText";
import PolicyIntroduction from "./PolicyIntroduction";
import FindOrganizationFromUrl from "../../../../components/FindOrganizationFromUrl";

const Policies = () => {
  const { policy, setPolicy, language } = useAppContext();

  // Used if the user enters the URL directly instead of clicking on the link
  // to the policy page from the organization homepage
  FindOrganizationFromUrl();

  useEffect(() => {
    const setThePolicy = async () => {
      const policy = await fetchPolicy();
      setPolicy(policy);
    };
    setThePolicy();
  }, [language]); // Fetch the policy again if the language changes

  useEffect(() => {}, [policy]);

  return (
    <>
      <PolicyIntroduction />
      <RenderContentfulRichText policy={policy} />
    </>
  );
};

export default Policies;
