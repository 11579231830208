import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const RenderContentfulRichText = (policy: any) => {
  return (
    <>
      {documentToReactComponents(policy.policy && policy.policy.fields.content)}
    </>
  );
};

export default RenderContentfulRichText;
