import { Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";

const ReportButtons: React.FC = ({}) => {
  const navigate = useNavigate();
  const { lang } = useParams();
  return (
    <>
      <Grid item sx={{ mt: 4, mb: 3 }}>
        <Button
          variant="contained"
          onClick={() => navigate("report")}
          endIcon={<ReportOutlinedIcon />}
          sx={{
            mr: 2,
            mb: { xs: 2, sm: 0 },
            width: { xs: "100%", sm: "auto" },
          }}
        >
          {t("buttonReport")}
        </Button>
        <Button
          onClick={() => {
            navigate(`/${lang}/followup`);
          }}
          variant="outlined"
          sx={{ width: { xs: "100%", sm: "auto" } }}
        >
          {t("buttonFollowUp")}
        </Button>
      </Grid>
    </>
  );
};

export default ReportButtons;
