import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useAppContext } from "../../../context/Context";
import { t } from "i18next";

const SelectorSubsidiary = () => {
  const { company, setSelectedCompanies } = useAppContext();
  const [allCompanyNames, setAllCompanyNames] = useState<string[]>([]);
  const [allCompanies, setAllCompanies] = useState<any[]>([company && company]);
  const [selectedCompanyNames, setSelectedCompanyNames] = useState<string[]>(
    []
  );

  useEffect(() => {
    // Find the company object from the selectedCompanyNames in the allCompanies array
    const filterCompanies = allCompanies.filter((company, index) => {
      return selectedCompanyNames.indexOf(company.name) !== -1;
    });
    setSelectedCompanies(filterCompanies as any);
  }, [selectedCompanyNames]);

  useEffect(() => {
    const tmpCompanyArray = [company && company];
    // Append company.subsidiaries to allCompanies
    if (company && company.subsidiaries && company.subsidiaries.length > 0) {
      const tmpCompanyNamesArray = [company && company.name];
      company.subsidiaries?.forEach((subsidiary: any) => {
        tmpCompanyArray.push(subsidiary);
        tmpCompanyNamesArray.push(subsidiary.name);
      });
      setAllCompanyNames(tmpCompanyNamesArray);
      setAllCompanies(tmpCompanyArray);
    }
  }, [company]);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedCompanyNames>
  ) => {
    const value = event.target.value;
    setSelectedCompanyNames(value as Array<string>);
  };

  return (
    <>
      <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
        <InputLabel
          id="demo-multiple-chip-label"
          sx={{ backgroundColor: "#f8f4f2", pr: 1 }}
        >
          {t("selectDepartment")}
        </InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedCompanyNames}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {allCompanyNames.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectorSubsidiary;
