import { List, ListItem, ListItemText, Button } from "@mui/material";
import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { UploadedFileDataInterface } from "../../../api/api";

interface UploadedFilesProps {
  files: UploadedFileDataInterface[];
}
const FileList: React.FC<UploadedFilesProps> = ({ files }) => {
  return (
    <List>
      {files.map((file, index) => (
        <ListItem
          key={index}
          sx={{
            borderRadius: 1,
            mt: 1,
            p: 0,
          }}
        >
          <DescriptionIcon sx={{ color: "#737370", mr: 2 }} />
          <ListItemText primary={file.display_name} />
        </ListItem>
      ))}
    </List>
  );
};

export default FileList;
