import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import theme from "./UI/theme";
import { ThemeProvider } from "@mui/material/styles";
import { AppContextProvider } from "./context/Context";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/HomePage";
import OrganizationSearch from "./pages/SearchOrganization";
import CompanyHome from "./pages/organization/OrganizationHomepage";
import { Box, CssBaseline, Grid } from "@mui/material";
import Followup from "./pages/followup/Followup";
import Report from "./pages/report/Report";
import Footer from "./components/Footer";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import Policies from "./pages/organization/components/policies/Policies";

const App: React.FC = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh", // Make the container full-height
        }}
      >
        <AppContextProvider>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <CssBaseline /> {/* Add this line to reset default styles */}
                <ResponsiveAppBar />
                <Box
                  flex="1" // Allow content to grow and occupy remaining space
                >
                  <Grid
                    container
                    justifyContent="center"
                    sx={{
                      m: "auto",
                      pt: 10,
                      pl: 5,
                      pr: 5,
                      pb: 10,
                      maxWidth: 1500,
                    }}
                  >
                    <Grid item xs={12} sm={10} md={8} lg={6} xl={5}>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <Navigate
                              // Redirect to the default language and remove the region code which is after the dash such as "en-US" -> "en"
                              to={i18n.options.lng?.split("-")[0] as string}
                            />
                          }
                        />
                        <Route path="/:lang/" element={<Home />} />
                        <Route
                          path="/:lang/organization"
                          element={<OrganizationSearch />}
                        />
                        <Route
                          path="/:lang/organization/:companyCodeFromUrl"
                          element={<CompanyHome />}
                        />
                        <Route
                          path="/:lang/organization/:companyCodeFromUrl/report"
                          element={<Report />}
                        />
                        <Route
                          path="/:lang/organization/:companyCodeFromUrl/policy"
                          element={<Policies />}
                        />
                        <Route path="/:lang/followup" element={<Followup />} />
                      </Routes>
                    </Grid>
                  </Grid>
                </Box>
                <Footer />
              </ThemeProvider>
            </BrowserRouter>
          </I18nextProvider>
        </AppContextProvider>
      </div>
    </>
  );
};

export default App;
