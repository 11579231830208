import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import your updated translation file
import translations from "./locales/translations.json";

// If a new language is added to /locales/translations.json
// you need to add the langauge below
const supportedLanguages = [
  { code: "en-US", name: "English" },
  { code: "sv-SE", name: "Svenska" },
];

// Create the resources object based on supported languages
const resources = {};
supportedLanguages.forEach((lang) => {
  resources[lang.code] = {
    translation: translations[lang.code], // Use translations from your file
    languageName: lang.name, // Include the language name
    longCode: lang.longCode, // Include the longer language code
  };
});

i18n.use(initReactI18next).init({
  lng: "en-US", // Default language
  supportedLngs: supportedLanguages.map((lang) => lang.code), // Language codes only
  fallbackLng: "en-US",
  resources, // Provide translations and language names
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
