import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFModel from "./PdfModel";
import DownloadIcon from "@mui/icons-material/Download";

interface HomeProps {
  password: string;
}

const DownloadCodeButton: React.FC<HomeProps> = ({ password }) => {
  const { t } = useTranslation();

  return (
    <>
      <PDFDownloadLink
        fileName={t("whistleblowingInstructionsPdfName")}
        document={<PDFModel password={password} />}
      >
        <Button variant="outlined" size="small" startIcon={<DownloadIcon />}>
          {t("downloadCodeButton")}
        </Button>
      </PDFDownloadLink>
    </>
  );
};

export default DownloadCodeButton;
