import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useAppContext } from "../context/Context";
import { Grid, Typography } from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import { t } from "i18next";

const UploadFiles = () => {
  const { selectedFiles, setSelectedFiles } = useAppContext();

  useEffect(() => {}, [selectedFiles]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    const fileList = Array.from(files); // Convert FileList to an array
    setSelectedFiles([...selectedFiles, ...fileList]); // Add selected files to the existing list
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1); // Remove the file at the specified index
    setSelectedFiles(updatedFiles);
  };

  return (
    <Grid container sx={{ mt: 0 }}>
      <Grid
        item
        textAlign="center"
        xs={12}
        sx={{
          border: "1px dashed #d8d8d8",
          borderRadius: 1,
          p: 3,
        }}
      >
        <Grid item>
          <CloudUploadIcon sx={{ fontSize: 40, color: "#61A496" }} />
        </Grid>
        <Typography variant="body1" paragraph>
          {t("uploadFilesDescr")}
        </Typography>
        <Grid item>
          <Button variant="outlined" component="label">
            {t("uploadFiles")}
            <input
              type="file"
              accept="*"
              multiple
              onChange={handleFileChange}
              hidden
            />
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <List>
          {selectedFiles.map((file, index) => (
            <ListItem
              key={index}
              sx={{
                border: "1px solid #d8d8d8",
                borderRadius: 1,
                mt: 1,
              }}
            >
              <DescriptionIcon sx={{ color: "#737370", mr: 2 }} />
              <ListItemText primary={file.name} />
              <Button
                color="error"
                variant="text"
                onClick={() => handleRemoveFile(index)}
                endIcon={<CancelRoundedIcon />}
              >
                {t("removeFile")}
              </Button>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default UploadFiles;
