import axios from "axios";
import { AxiosResponse } from "axios";

// const api_url = "https://whistle-wizard-backend-kkskfwpmnq-lz.a.run.app/api";
const api_url = process.env.REACT_APP_BACKEND_API_URL;

export interface Subsidiary {
  uid: string;
  name: string;
  organization_number: string;
  company_code?: string;
}

export interface Organization {
  uid: string;
  name: string;
  organization_number: string;
  company_code?: string;
  subsidiaries?: Subsidiary[] | [];
}

export const fetchOrganization = async (
  company_code: string
): Promise<Organization> => {
  try {
    const response = await axios.get<Organization>(
      `${api_url}/organizations/?company_code=${company_code}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch organization data");
  }
};

export interface IReportCreate {
  organization_ids: string[] | undefined;
  uid: string;
  created_at: string;
  misconduct_subject: string;
  misconduct_conductor: string;
  misconduct_date: string;
  misconduct_location: string;
  misconduct_description: string;
  reported_before: string;
  previous_actions: string;
  contact_details: string;
  file?: File[];
}

export interface IReportFetchResponse {
  organizations: Organization[] | undefined;
  uid: string;
  created_at: string;
  misconduct_subject: string;
  misconduct_conductor: string;
  misconduct_date: string;
  misconduct_location: string;
  misconduct_description: string;
  reported_before: string;
  previous_actions: string;
  contact_details: string;
  file?: File[];
}

export interface InterfaceTextFields {
  misconduct_subject: string;
  misconduct_conductor?: string;
  misconduct_date?: string;
  misconduct_location?: string;
  misconduct_description: string;
  reported_before?: string;
  previous_actions?: string;
  contact_details?: string;
}

export interface IFormDataReport extends FormData {}

// Define the function to upload multiple files
export const uploadMultipleFiles = async (
  reportId: string,
  files: File[]
): Promise<AxiosResponse<any>> => {
  const formData = new FormData();

  files.forEach((file, index) => {
    formData.append(`files`, file, file.name);
  });

  try {
    const response = await axios.post(
      `${api_url}/files/upload?report_id=${reportId}`,
      formData,
      {
        headers: {
          accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Failed to upload files");
  }
};

export const createReportWithFiles = async (
  reportData: IFormDataReport
): Promise<IReportCreate> => {
  try {
    const response = await axios.post<IReportCreate>(
      `${api_url}/reports`,
      reportData,
      {
        headers: {
          accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to create report");
  }
};

export const fetchReport = async (
  report_uid: string
): Promise<IReportFetchResponse> => {
  try {
    const response = await axios.get<IReportFetchResponse>(
      `${api_url}/reports/${report_uid}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch report");
  }
};

export interface Message {
  content: string;
  report_id: string;
  created_at: string;
}

export interface MessageList {
  messages: Message[];
}

export interface MessageList extends Array<Message> {}

export const fetchMessages = async (
  report_uid: string
): Promise<MessageList> => {
  try {
    const response = await axios.get<MessageList>(
      `${api_url}/messages/${report_uid}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch messages");
  }
};

export interface SendMessageRequest {
  report_id: string;
  content: string;
}

export interface SendMessageResponse {
  content: string;
  report_id: string;
  created_at: string;
}

export const sendMessage = async (
  messageData: SendMessageRequest
): Promise<SendMessageResponse> => {
  try {
    const formData = new FormData();
    formData.append("report_id", messageData.report_id);
    formData.append("content", messageData.content);

    const response = await axios.post<any>(`${api_url}/messages`, formData, {
      headers: {
        accept: "*/*",
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to send message");
  }
};

export interface UploadedFileDataInterface {
  uid: string;
  report_id: string;
  uploaded_at: string;
  display_name: string;
}

export interface UploadedFileList extends Array<UploadedFileDataInterface> {}

export const fetchUploadedFiles = async (
  report_uid: string
): Promise<UploadedFileList> => {
  try {
    const response = await axios.get<UploadedFileList>(
      `${api_url}/files?report_id=${report_uid}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch messages");
  }
};
