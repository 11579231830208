import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import PoliciesButton from "./components/PoliciesButton";
import IntroductionText from "./components/IntroductionText";
import ReportButtons from "./components/ReportButtons";
import RenderOrganizations from "./components/RenderOrganizations";
import FindOrganizationFromUrl from "../../components/FindOrganizationFromUrl";

interface HomeProps {}

const CompanyHome: React.FC<HomeProps> = ({}) => {
  const { t } = useTranslation();

  // Used if the user enters the URL directly instead of searching for the company
  FindOrganizationFromUrl();

  return (
    <>
      <IntroductionText />
      <RenderOrganizations />
      <ReportButtons />
      <PoliciesButton />

      <Typography variant="h2" paragraph sx={{ mt: 3 }}>
        {t("reportInformation")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("howToReportParagraph")}
      </Typography>
      <Typography variant="h3" paragraph>
        {t("anonymity")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("anonymityParagraph")}
      </Typography>

      <Typography variant="h3" paragraph>
        {t("processingAReport")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("processingAReportParagraph1")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("processingAReportParagraph2")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("processingAReportParagraph3")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("processingAReportParagraph4")}
      </Typography>
    </>
  );
};

export default CompanyHome;
