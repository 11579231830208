import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../context/Context";
import { Chip, Grid, Typography } from "@mui/material";
import { t } from "i18next";

const RenderOrganizations = () => {
  const { company } = useAppContext();
  const [allCompanies, setAllCompanies] = useState<any[]>([company && company]);

  useEffect(() => {
    const tmpCompanyArray = [company && company];
    // Append company.subsidiaries to allCompanies
    if (company && company.subsidiaries && company.subsidiaries.length > 0) {
      const tmpCompanyNamesArray = [company && company.name];
      company.subsidiaries?.forEach((subsidiary: any) => {
        tmpCompanyArray.push(subsidiary);
        tmpCompanyNamesArray.push(subsidiary.name);
      });
      setAllCompanies(tmpCompanyArray);
    } else {
      setAllCompanies([company && company]);
    }
  }, [company]);

  const renderOrganizations = () => {
    if (company) {
      return allCompanies.map((company: any, index: number) => {
        return <Chip label={company && company.name} sx={{ ml: 0.5, mb: 1 }} />;
      });
    }
  };

  // If a company has subsidiaries, then render the following component, otherwise, don't render anything
  const renderComponent = () => {
    if (company && company.subsidiaries && company.subsidiaries.length > 0) {
      return (
        <Grid
          container
          sx={{
            mt: 3,
            mb: 1,
          }}
        >
          <Typography paragraph>
            {t("channelCanBeUsedByTheFollowingOrganizations")}:
          </Typography>
          <Grid item>{renderOrganizations()}</Grid>
        </Grid>
      );
    }
  };
  return <>{renderComponent()}</>;
};

export default RenderOrganizations;
