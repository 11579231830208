import { Grid, TextField } from "@mui/material";
import { t } from "i18next";
import { useAppContext } from "../../../../context/Context";

const RenderTextFields = () => {
  const { textFields: contextTextFields, setTextFields: setContextTextFields } =
    useAppContext();

  const handleTextFieldChange = (value: string, field: string) => {
    setContextTextFields((prevTextFields: any) => ({
      ...prevTextFields,
      [field]: value,
    }));
  };

  const textField = (
    field: string,
    label: any,
    rows: number,
    required: boolean = true
  ) => {
    return (
      <Grid item sx={{ mt: 2, mb: 2 }}>
        <TextField
          label={label}
          required={required}
          variant="outlined"
          value={contextTextFields[field as keyof typeof contextTextFields]}
          fullWidth
          onChange={(e) => handleTextFieldChange(e.target.value, field)}
          rows={rows}
          multiline={(rows > 0 && true) || false}
          autoComplete="off"
        />
      </Grid>
    );
  };

  return (
    <>
      {textField("misconduct_subject", t("formSubjectLabel"), 0)}
      {textField("misconduct_conductor", t("formWhoLabel"), 0, false)}
      {textField("misconduct_date", t("formWhenLabel"), 0, false)}
      {textField("misconduct_location", t("formWhereLabel"), 0, false)}
      {textField("misconduct_description", t("formDescrLabel"), 8)}
      {textField("reported_before", t("formReportedBeforeLabel"), 0, false)}
      {textField("previous_actions", t("formPreviousActionsLabel"), 0, false)}
      {textField("contact_details", t("formContactDetailsLabel"), 0, false)}
    </>
  );
};

export default RenderTextFields;
